import React, { useState } from "react";
import { Card, Button, Modal, Row, Col } from "react-bootstrap";

export const ViewInteresados = ({ interesado }) => {
    const [selectedInteresado, setSelectedInteresado] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleShow = (interesado) => {
        setSelectedInteresado(interesado);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedInteresado(null);
    };

    const interesadosArray = Array.isArray(interesado) ? interesado : [interesado];
    const singleView = interesadosArray.length === 1;

    return (
        <div>
            {interesadosArray.length > 0 ? (
                singleView ? (
                    <Card
                        className="border-0 shadow-sm my-3"
                        style={{
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Card.Header
                            className="text-white"
                            style={{
                                backgroundColor: "#52CA11",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                            }}
                        >
                            {interesadosArray[0].nombre_interesado}
                        </Card.Header>
                        <Card.Body style={{ padding: "1.5rem" }}>
                            <Row>
                                <Col md={6}>
                                    <p><strong>Empresa:</strong> {interesadosArray[0].compania_clasificacion}</p>
                                    <p><strong>Rol:</strong> {interesadosArray[0].rol}</p>
                                    <p><strong>Cargo:</strong> {interesadosArray[0].cargo}</p>
                                </Col>
                                <Col md={6}>
                                    <p><strong>Teléfono:</strong> {interesadosArray[0].telefono}</p>
                                    <p><strong>Email:</strong> {interesadosArray[0].email}</p>
                                    <p><strong>Otros Datos:</strong> {interesadosArray[0].otros_datos_contacto || "No especificado"}</p>
                                </Col>
                            </Row>
                            <Button
                                variant="light"
                                style={{
                                    backgroundColor: "#52CA11",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "20px",
                                    padding: "0.5rem 1.5rem",
                                    fontWeight: "bold",
                                }}
                                onClick={() => handleShow(interesadosArray[0])}
                            >
                                Ver Más
                            </Button>
                        </Card.Body>
                    </Card>
                ) : (
                    <Row className="g-4">
                        {interesadosArray.map((interesadoItem, index) => (
                            <Col key={index} md={6} lg={4}>
                                <Card
                                    className="border-0 shadow-sm"
                                    style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Card.Header
                                        className="text-white"
                                        style={{
                                            backgroundColor: "#52CA11",
                                            fontWeight: "bold",
                                            fontSize: "1.1rem",
                                        }}
                                    >
                                        {interesadoItem.nombre_interesado}
                                    </Card.Header>
                                    <Card.Body style={{ padding: "1rem 1.5rem" }}>
                                        <p><strong>Cargo:</strong> {interesadoItem.cargo}</p>
                                        <p><strong>Teléfono:</strong> {interesadoItem.telefono}</p>
                                        <p><strong>Email:</strong> {interesadoItem.email}</p>
                                        <Button
                                            variant="light"
                                            style={{
                                                backgroundColor: "#52CA11",
                                                color: "#fff",
                                                border: "none",
                                                borderRadius: "20px",
                                                padding: "0.4rem 1rem",
                                                fontSize: "0.9rem",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => handleShow(interesadoItem)}
                                        >
                                            Ver Más
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )
            ) : (
                <div className="text-center mt-3">
                    <p>No hay interesados registrados en este momento.</p>
                </div>
            )}

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedInteresado ? `Detalles de ${selectedInteresado.nombre_interesado}` : "Detalles"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedInteresado && (
                        <div>
                            <p><strong>Nombre:</strong> {selectedInteresado.nombre_interesado}</p>
                            <p><strong>Empresa:</strong> {selectedInteresado.compania_clasificacion}</p>
                            <p><strong>Teléfono:</strong> {selectedInteresado.telefono}</p>
                            <p><strong>Email:</strong> {selectedInteresado.email}</p>
                            <p><strong>Rol:</strong> {selectedInteresado.rol}</p>
                            <p><strong>Cargo:</strong> {selectedInteresado.cargo}</p>
                            <p><strong>Otros Datos:</strong> {selectedInteresado.otros_datos_contacto || "No especificado"}</p>
                            <p><strong>Expectativas:</strong> {selectedInteresado.expectativas || "No especificado"}</p>
                            <p><strong>Fecha de Creación:</strong> {new Date(selectedInteresado.fecha_creacion).toLocaleDateString()}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        style={{
                            backgroundColor: "#52CA11",
                            color: "#fff",
                            border: "none",
                            borderRadius: "20px",
                            padding: "0.4rem 1.2rem",
                            fontWeight: "bold",
                        }}
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ViewInteresados;
